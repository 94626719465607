import classNames from "classnames"
import React from "react"

import { useTranslation } from "@bounce/i18n"
import { Button } from "@bounce/web-components"

import { FeatureFlag, useFeatureFlag } from "../../../config/flags"
import { useUrl } from "../../../libs/router"
import { goToApp, useAppURL } from "../../../utils/appURL"
import { usePrimaryCtaColor } from "../../../hooks/usePrimaryCtaColor"

export type StickyCtaProps = {
  coupon?: string
  from?: string
  isVisible: boolean
  label?: string
  searchQuery?: string
  path?: string
}

export const StickyCTA = ({ isVisible, searchQuery = "", coupon, from, label, path = "/s" }: StickyCtaProps) => {
  const { t } = useTranslation()
  const buildAppURL = useAppURL()
  const { getUrl } = useUrl()
  const href = buildAppURL(getUrl({ path }), { query: searchQuery, coupon })
  const [isSeeNearbyStorageSearchCtaEnabled] = useFeatureFlag(FeatureFlag.SeeNearbyStorageSearchCta)
  const defaultCtaTxt = isSeeNearbyStorageSearchCtaEnabled
    ? t("cmp.stickyCTA.ctaV1", "See nearby storage")
    : t("cmp.stickyCTA.cta", "Find closest locations")

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()

    return goToApp(href, { cta: `StickyCTA${from}` })
  }

  const ctaColor = usePrimaryCtaColor()

  return (
    <div
      className={classNames(
        "sticky bottom-0 z-1 bg-white p-5 opacity-0 shadow-[0px_-8px_12px_0px_#00000008] duration-200",
        isVisible ? "translate-y-0 opacity-100" : "translate-y-full",
      )}>
      <Button
        href={href}
        rel="nofollow"
        onClick={onClick}
        color={ctaColor}
        className="w-full"
        textClassName="line-clamp-1">
        {label ?? defaultCtaTxt}
      </Button>
    </div>
  )
}
